import {Card, CardBody, CardFooter, CardHeader, Col, Row, Table, UncontrolledCollapse} from "reactstrap"
import Select from "react-select"
import SimpleBar from "simplebar-react"
import {Link} from "react-router-dom"
import React, {useContext, useEffect, useRef, useState} from "react"
import {
  addComment,
  attachEmployee, changeAdditionalStatus,
  changeDepartment,
  changePlan,
  changeStatus,
  changeVerificationStatus,
  editComment,
  deleteComment,
  getComments,
} from "../../../api/customers"
import {toast} from "react-toastify"
import {selectListData, selectListDataWithParam} from "../../../api/general"
import {ModalContext} from "../../../contexts"
import EditProfile from "./Modals/EditProfile"
import ChangePassword from "./Modals/ChangePassword"
import AddCommentsToCalendar from "./Modals/AddCommentsToCalendar"
import classnames from "classnames"
import {useUserCan, useUserHasRole} from "../../../Components/Hooks/UserHooks"
import {copyToClipboard, prettyDate} from "../../../helpers/utils"
import FeesConfigTable from "./Components/FeesConfigTable"
import {Tooltip} from "react-tooltip"
import ProfileRevisionsModal from "./Modals/ProfileRevisionsModal"

const Overview = (props) => {
  const {
    profileData,
    assignedEmployee,
    customerDepartment,
    updateProfile,
    activeTab,
    profileRevisions,
  } = props
  const {openModal} = useContext(ModalContext)

  const [pendingProfileRevisions, setPendingProfileRevisions] = useState(0)
  // Verification status
  const [verificationStatuses, setVerificationStatuses] = useState([])
  const [currentVerificationStatus, setCurrentVerificationStatus] = useState({})
  // Status
  const [statuses, setStatuses] = useState([])
  const [currentStatus, setCurrentStatus] = useState({})
  // Plans
  const [plans, setPlans] = useState([])
  const [currentPlan, setCurrentPlan] = useState({})
  // Additional Statuses
  const [additionalStatuses, setAdditionalStatuses] = useState([])
  const [currentAdditionalStatus, setCurrentAdditionalStatus] = useState({})
  // Comments
  const [comments, setComments] = useState([])
  const [isCommentsLoading, setIsCommentsLoading] = useState(true)
  const [commentInput, setCommentInput] = useState('')
  const [oldId, setOldId] = useState(0)
  const [commentMessage, setCommentMessage] = useState()
  const [commentId, setCommentId] = useState()
  const [isEditCommentForm, setIsEditCommentForm] = useState(false)
  // Agents
  const [agentOptions, setAgentOptions] = useState([])
  const [selectedAgent, setSelectedAgent] = useState('')
  // Departments
  const [departmentsOptions, setDepartmentsOptions] = useState([])
  const [selectedDepartment, setSelectedDepartment] = useState('')
  // Scrollbar
  const scrollBarRef = useRef(null)
  // Modal AddCalendarEvent
  const [isModalOpen, setIsModalOpen] = useState([])
  const [messageData, setMessageData] = useState({})
  // Modal AddCommentsToCalendar Button
  const [commentHovered, setCommentHovered] = useState(new Array(comments.length).fill(false))
  const [hoveredButton, setHoveredButton] = useState(false)
  // Fees Configurations Table
  const [isFeesConfigTable, setIsFeesConfigTable] = useState(false)

  // Permissions
  const isHasRoles = useUserHasRole('admin', 'super_admin')
  const canChangePlan = useUserCan('change_plan')
  const canVerifyCustomer = useUserCan('verify_customer')
  const canAttachEmployee = useUserCan('assign_employee')
  const canEditCustomer = useUserCan('edit_customer')
  const canChangePassword = useUserCan('change_password')
  const canEditProfileComment = useUserCan('edit_profile_comment')
  const canDeleteProfileComment = useUserCan('delete_profile_comment')
  const canViewAdditionalStatus = useUserCan('view_additional_status')
  const canChangeAdditionalStatus = useUserCan('change_additional_status')
  const canChangeStatus = useUserCan('change_status')
  const canAddProfileComment = useUserCan('add_profile_comment')
  const canManageRevisions = useUserCan('manage_revisions')

  const getStatuses = () => {
    return selectListDataWithParam('customer_status', {customer_id: profileData.user_id}).then(r => {
      setStatuses(r)
    })
  }

  const openModalWithData = (messageData, idx) => {
    setMessageData(messageData)
    setIsModalOpen((prevState) => {
      const newState = [...prevState]
      newState[idx] = true
      return newState
    })
  }

  const closeModal = (idx) => {
    setMessageData({})
    setIsModalOpen((prevState) => {
      const newState = [...prevState]
      newState[idx] = false
      return newState
    })
  }

  const scrollChatToBottom = () => {
    let chatWindow = scrollBarRef.current.el.querySelector('.simplebar-content-wrapper')
    chatWindow.scrollTo(0, chatWindow.scrollHeight)
  }

  const getCommentsData = () => {
    getComments(profileData.user_id).then(r => {
      setComments(r)
      setIsCommentsLoading(false)
      setTimeout(() => {
        scrollChatToBottom()
      }, 100)
    })
  }

  const openEditProfileModal = () => {
    openModal({
      title: 'Edit Profile',
      content: <EditProfile profileData={profileData} updateProfile={updateProfile}/>,
      size: "lg",
    })
  }

  const openProfileRevisionsHandler = () => {
    openModal({
      title: 'Revisions',
      content: <ProfileRevisionsModal profileRevisions={profileRevisions} updateProfile={updateProfile}/>,
    })
  }

  const changePasswordHandler = () => {
    openModal({
      title: 'Change Password',
      content: <ChangePassword id={profileData.user_id}/>,
    })
  }

  const changeStatusHandler = selected => {
    setCurrentStatus(selected)
    changeStatus(selected.value, [profileData.user_id]).then(r => {
      if (r.success) {
        toast.success(r.message)
      } else {
        toast.error(r.message)
      }
    })
  }

  const changeAdditionalStatusHandler = selected => {
    setCurrentAdditionalStatus(selected)
    changeAdditionalStatus(selected.value, [profileData.user_id]).then(r => {
      if (r.success) {
        toast.success(r.message)
      } else {
        toast.error(r.message)
      }
    })
  }

  const changePlanHandler = selected => {
    setCurrentPlan(selected)
    changePlan(selected.value, [profileData.user_id]).then(r => {
      if (r.success) {
        toast.success(r.message)
      } else {
        toast.error(r.message)
      }
    })
  }

  const changeVerificationStatusHandler = selected => {
    setCurrentVerificationStatus(selected)
    changeVerificationStatus(selected.value, [profileData.user_id]).then(r => {
      if (r.success) {
        toast.success(r.message)
      } else {
        toast.error(r.message)
      }
    })
  }

  const changeAgentHandler = selected => {
    setSelectedAgent(selected)
    attachEmployee(selected.value, [profileData.user_id]).then(r => {
      if (r.success) {
        toast.success(r.message)
        ;(async () => {
          await getStatuses()
          updateProfile()
        })()
      } else {
        toast.error(r.message)
      }
    })
  }

  const changeDepartmentHandler = selected => {
    setSelectedDepartment(selected)
    changeDepartment(selected.value, [profileData.user_id]).then(r => {
      if (r.success) {
        toast.success(r.message)
      } else {
        toast.error(r.message)
      }
    })
  }

  const addCommentHandler = () => {
    addComment(profileData.user_id, commentInput).then(r => {
      if (r.success) {
        toast.success(r.message)
        getCommentsData()
      } else {
        toast.error(r.message)
      }
    })

    setCommentInput('')
  }

  const editCommentResponse = (id, data) => {
    editComment(id, data).then(r => {
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        getCommentsData()
        setCommentId('')
        setIsEditCommentForm(false)
        toast.success(message)
      } else {
        toast.error(message)
      }
    }).catch(r => {
      if (r.errors) {
        Object.entries(r.errors).forEach(entry => {
          const [key, value] = entry
          value.forEach(i => toast.error(i))
        })
      }
    })
  }

  const editCommentHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())

    editCommentResponse(commentId, data)
  }

  const deleteCommentHandler = (id) => {
    if (confirm('Do you really want to delete this comment?')) {
      deleteComment(id).then(r => {
        let message = r?.message || 'Empty response message.'

        if (r.success) {
          getCommentsData()
          toast.success(message)
        } else {
          toast.error(message)
        }
      }).catch(r => {
        if (r.errors) {
          Object.entries(r.errors).forEach(entry => {
            const [key, value] = entry
            value.forEach(i => toast.error(i))
          })
        }
      })
    }
  }

  useEffect(() => {
    if (agentOptions.length && Object.keys(assignedEmployee || {}).length) {
      setSelectedAgent(agentOptions.find(i => assignedEmployee.id === i.value))
    } else {
      setSelectedAgent('')
    }
  }, [agentOptions, assignedEmployee, profileData.id])

  useEffect(() => {
    if (departmentsOptions.length && customerDepartment) {
      setSelectedDepartment(departmentsOptions.find(i => customerDepartment.id === i.value))
    }
  }, [departmentsOptions, customerDepartment])

  useEffect(() => {
    if (profileRevisions?.length) {
      setPendingProfileRevisions(Number(profileRevisions.filter(i => i.status === 'pending')?.length))
    }
  }, [profileRevisions])

  useEffect(() => {
    getStatuses()
  }, [profileData])

  useEffect(() => {
    if (statuses.length) {
      setCurrentStatus(statuses.find(i => profileData.status_id === i.value))
    }
  }, [profileData, statuses])

  useEffect(() => {
    if (plans.length) {
      setCurrentPlan(plans.find(i => profileData.plan === i.value))
    }
  }, [profileData, plans])

  useEffect(() => {
    if (additionalStatuses?.length) {
      setCurrentAdditionalStatus(additionalStatuses.find(i => profileData.additional_status === i.value))
    }
  }, [profileData, additionalStatuses])

  useEffect(() => {
    if (verificationStatuses.length) {
      setCurrentVerificationStatus(verificationStatuses.find(i => profileData.verification_status === i.value))
    }
  }, [profileData, verificationStatuses])

  useEffect(() => {
    let newId = profileData.user_id

    if (activeTab === 'overview' && Object.keys(profileData).length) {
      if (newId !== oldId) {
        getCommentsData()
        setOldId(profileData.user_id)
      }
    }
  }, [profileData, activeTab])

  useEffect(() => {
    selectListData('agents').then((r) => {
      setAgentOptions(r)
    })

    selectListData('departments').then((r) => {
      setDepartmentsOptions(r)
    })

    selectListData('customer_plans').then(r => {
      setPlans(Object.values(r))
    })

    selectListData('customer_verification_statuses').then(r => {
      setVerificationStatuses(Object.values(r))
    })

    selectListData('additional_statuses').then(r => {
      setAdditionalStatuses(r)
    })

    if (scrollBarRef.current) {
      const scrollElement = scrollBarRef.current.contentWrapperEl
      scrollElement.scrollTop = scrollElement.scrollHeight
    }
  }, [])

  return (
    <Row>
      <Col xl={5}>
        <Card>
          <CardBody>
            <div className="d-flex align-items-center mb-4">
              <div className="flex-grow-1">
                <h5 className="card-title mb-0">Profile Info</h5>
              </div>
              <div className="d-flex flex-column flex-shrink-0 align-items-end">
                <div className="mb-1">
                  {canChangePassword &&
                    <Link to="#" className="badge bg-light text-primary fs-12 me-2" onClick={changePasswordHandler}>
                      <i className="ri-lock-line align-bottom me-1"></i> Change Password
                    </Link>}
                  {canEditCustomer &&
                    <Link to="#" className="badge bg-light text-primary fs-12" onClick={openEditProfileModal}>
                      <i className="ri-edit-box-line align-bottom me-1"></i> Edit
                    </Link>}
                </div>
                {canManageRevisions &&
                <div>
                  <Link to="#" className="badge bg-light text-primary fs-12 d-inline-flex align-items-center"
                        onClick={openProfileRevisionsHandler}>
                    <i className="ri-edit-box-line align-bottom me-1"></i>
                    Revisions {pendingProfileRevisions ?
                    <span className="badge bg-danger ms-1">{pendingProfileRevisions}</span> : null}
                  </Link>
                </div>}
              </div>
            </div>
            <div className="table-responsive overflow-visible">
              <Table className="table-borderless mb-0">
                <tbody>
                <tr>
                  <th className="ps-0 text-nowrap align-middle" scope="row">Full Name :</th>
                  <td className="text-muted">
                    <span
                      className="cursor-pointer"
                      onClick={() => copyToClipboard((profileData.first_name + ' ' + profileData.last_name))}
                    >
                      {profileData.first_name} {profileData.last_name}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th className="ps-0 text-nowrap align-middle" scope="row">Phone :</th>
                  <td className="text-muted">
                    <span className="cursor-pointer" onClick={() => copyToClipboard(profileData.phone)}>
                      {profileData.phone}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th className="ps-0 text-nowrap align-middle" scope="row">E-mail :</th>
                  <td className="text-muted">
                    <span className="cursor-pointer" onClick={() => copyToClipboard(profileData.email)}>
                      {profileData.email}
                    </span>
                  </td>
                </tr>

                <tr>
                  <th className="ps-0 text-nowrap align-middle" scope="row">Status :</th>
                  <td className="text-muted">
                    <Select
                      onChange={selected => {
                        changeStatusHandler(selected)
                      }}
                      isDisabled={!canChangeStatus}
                      value={currentStatus}
                      options={statuses}
                    />
                  </td>
                </tr>
                {canChangePlan &&
                  <tr>
                    <th className="ps-0 text-nowrap align-middle" scope="row">Plan :</th>
                    <td className="text-muted">
                      <Select
                        onChange={selected => {
                          changePlanHandler(selected)
                        }}
                        value={currentPlan}
                        options={plans}
                      />
                    </td>
                  </tr>}
                {canVerifyCustomer &&
                  <tr>
                    <th className="ps-0 text-nowrap align-middle" scope="row">Verification :</th>
                    <td className="text-muted">
                      <Select
                        onChange={selected => {
                          changeVerificationStatusHandler(selected)
                        }}
                        value={currentVerificationStatus}
                        options={verificationStatuses}
                      />
                    </td>
                  </tr>}
                {profileData.source &&
                  <tr>
                    <th className="ps-0 text-nowrap" scope="row">Source :</th>
                    <td className="text-muted">{profileData.source}</td>
                  </tr>
                }

                <tr>
                  <th className="ps-0 text-nowrap align-middle" scope="row">Agent :</th>
                  <td className="text-muted">
                    <Select
                      value={selectedAgent}
                      options={agentOptions}
                      isDisabled={!canAttachEmployee}
                      onChange={selected => {
                        changeAgentHandler(selected)
                      }}
                    />
                  </td>
                </tr>
                {isHasRoles &&
                  <tr>
                    <th className="ps-0 text-nowrap align-middle" scope="row">Department :</th>
                    <td className="text-muted">
                      <Select
                        value={selectedDepartment}
                        options={departmentsOptions}
                        onChange={selected => {
                          changeDepartmentHandler(selected)
                        }}
                      />
                    </td>
                  </tr>}
                {canViewAdditionalStatus &&
                  <tr>
                    <th className="ps-0 text-nowrap align-middle" scope="row">Additional Status :</th>
                    <td className="text-muted">
                      <Select
                        value={currentAdditionalStatus}
                        options={additionalStatuses}
                        isDisabled={!canChangeAdditionalStatus}
                        onChange={selected => {
                          changeAdditionalStatusHandler(selected)
                        }}
                      />
                    </td>
                  </tr>}
                <tr>
                  <th className="ps-0 text-nowrap" scope="row">Country :</th>
                  <td className="text-muted">{profileData?.customerCountry || 'None'}</td>
                </tr>
                <tr>
                  <th className="ps-0 text-nowrap" scope="row">Joining Date :</th>
                  <td className="text-muted">
                    {prettyDate(profileData.created_at)}
                  </td>
                </tr>
                <tr>
                  <th className="ps-0 text-nowrap" scope="row">Last visit :</th>
                  <td className="text-muted">
                    {profileData?.last_visit_date ? prettyDate(profileData.last_visit_date) : 'Not detected'}
                  </td>
                </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h5 className="card-title mb-3">About</h5>
            <p>{profileData?.details || 'No details provided'}</p>
          </CardBody>
        </Card>
      </Col>

      <Col xl={7}>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0  me-2">Comments</h4>
              </CardHeader>
              <CardBody>
                <div className="profile-timeline">
                  <SimpleBar style={{maxHeight: "470px"}} ref={scrollBarRef} className="px-3">
                    {isCommentsLoading ?
                      <p className='text-center'>Loading...</p> :
                      comments.length ?
                        comments.map((comment, idx) => {
                          return <div className="accordion accordion-flush" key={idx}>
                            <div
                              className="accordion-item border-0"
                              onMouseEnter={() => setCommentHovered((prevState) => {
                                const newState = [...prevState]
                                newState[idx] = true
                                return newState
                              })}
                              onMouseLeave={() => setCommentHovered((prevState) => {
                                const newState = [...prevState]
                                newState[idx] = false
                                return newState
                              })}>
                              <div className="accordion-header d-flex">
                                <Link
                                  to="#"
                                  className="accordion-button p-2 shadow-none"
                                  id={`collapse_${comment.id}_${idx}`}
                                >
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 avatar-xs">
                                      <div className="avatar-title bg-light text-muted rounded-circle">
                                        <i className="ri-user-3-fill"></i>
                                      </div>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                      <h6 className="fs-14 mb-1">{comment.employee.name}</h6>
                                      <small className="text-muted">
                                        Commented date {prettyDate(comment.created_at)}
                                      </small>
                                    </div>
                                  </div>
                                </Link>
                                <Link
                                  to="#"
                                  onMouseEnter={() => setHoveredButton(true)}
                                  onMouseLeave={() => setHoveredButton(false)}
                                  className={classnames("btn text-muted fs-24 rounded-circle", {
                                    "d-flex": commentHovered[idx],
                                    "d-none": !commentHovered[idx],
                                    "bg-soft-info": hoveredButton,
                                  })}
                                  onClick={() => openModalWithData(comment, idx)}
                                >
                                  <i className=" ri-calendar-check-line"></i>
                                </Link>
                                {isModalOpen[idx] && (
                                  <AddCommentsToCalendar
                                    comment={comment}
                                    closeModal={() => closeModal(idx)}
                                  />
                                )}
                              </div>
                              {commentId !== comment.id &&
                                <UncontrolledCollapse toggler={`collapse_${comment.id}_${idx}`} defaultOpen>
                                  <div
                                    className="accordion-body keep-lines ms-2 ps-5 d-flex text-truncate position-relative">
                                    {comment.message}

                                    <div
                                      className={classnames('position-absolute text-muted fs-12 px-1 pb-0', {
                                        "d-flex": commentHovered[idx],
                                        "d-none": !commentHovered[idx],
                                      })}
                                      style={{left: '-10px', top: '16px'}}
                                    >
                                      {canDeleteProfileComment &&
                                        <i className="ri-delete-bin-line text-muted fs-12 px-1 pb-0 cursor-pointer"
                                           onClick={() => deleteCommentHandler(comment.id)}
                                        ></i>}
                                      {canEditProfileComment &&
                                        <i className="ri-edit-2-line ms-1 text-muted fs-12 px-1 pb-0 cursor-pointer"
                                           onClick={(e) => {
                                             setCommentMessage(comment.message)
                                             setCommentId(comment.id)
                                             setIsEditCommentForm(true)
                                           }}
                                        ></i>}
                                    </div>

                                  </div>
                                </UncontrolledCollapse>
                              }
                              {(isEditCommentForm && commentId === comment.id) &&
                                <form
                                  className="ps-5 position-relative"
                                  onSubmit={editCommentHandler}
                                >
                                <textarea
                                  name="comment"
                                  className="form-control border-soft-dark text-muted pe-5"
                                  value={commentMessage}
                                  rows={3}
                                  ref={(textarea) => textarea && textarea.focus()}
                                  onKeyDown={(e) => {
                                    if (e.key === 'Escape') {
                                      setCommentId('')
                                      setIsEditCommentForm(false)
                                    }

                                    if (e.key === 'Enter' && !e.shiftKey) {
                                      e.preventDefault()
                                      setCommentMessage(e.target.value)
                                      editCommentResponse(commentId, {comment: e.target.value})
                                    }
                                  }}
                                  onChange={(e) => setCommentMessage(e.target.value)}
                                />
                                  <div
                                    className="d-flex justify-content-end position-absolute"
                                    style={{top: '2px', right: '10px'}}
                                  >
                                    <button
                                      className="text-muted fs-16 btn btn-sm p-0 m-0"
                                      type="submit"
                                    >
                                      <i className="ri-checkbox-circle-line"></i>
                                    </button>
                                    <button
                                      className="text-muted fs-16 ms-2 btn btn-sm p-0 m-0"
                                      onClick={() => {
                                        setCommentId('')
                                        setIsEditCommentForm(false)
                                      }}
                                    >
                                      <i className="ri-close-circle-line"></i>
                                    </button>
                                  </div>
                                </form>
                              }
                            </div>
                          </div>
                        }) :
                        <p className='text-center'>No data</p>
                    }
                  </SimpleBar>
                </div>
              </CardBody>
              {canAddProfileComment &&
                <CardFooter>
                  <div className="input-group">
                  <textarea
                    onChange={e => setCommentInput(e.target.value)}
                    onKeyDown={e => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault()
                        setCommentInput(e.target.value)
                        addCommentHandler()
                      }
                    }}
                    value={commentInput}
                    rows={2}
                    className="form-control"
                  />
                    <button
                      onClick={addCommentHandler}
                      className="btn btn-success"
                      type="button"
                    >
                      Add
                    </button>
                  </div>
                </CardFooter>}
            </Card>
          </Col>
        </Row>

        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center">
              <div className="card-title mb-0 d-flex align-items-center">
                <h5 className="mb-0">Fees Configurations</h5>
                <i
                  className={`bx bx-chevrons-${isFeesConfigTable ? 'up' : 'down'} ms-1 fs-18 cursor-pointer text-primary`}
                  onClick={() => setIsFeesConfigTable(!isFeesConfigTable)}
                ></i>
              </div>
              {isFeesConfigTable ?
                <div className="d-flex align-items-center">
                  <div className="bg-primary me-1" style={{width: '50px', height: '20px'}}></div>
                  <span>- modified values</span>
                  <div
                    className="d-flex align-items-center ms-2 cursor-pointer"
                    data-tooltip-content={'If the \'Disabled\' value is checked in the \'Config\' settings, this type of fee will not be displayed in the table.'}
                    data-tooltip-id={'fees-table-info'}
                  >
                    <i className="ri ri-question-line text-primary fs-18"></i>
                  </div>
                  <Tooltip id={'fees-table-info'} style={{maxWidth: '300px', zIndex: '5'}} className="text-center"/>
                </div> : null
              }
            </div>
            {isFeesConfigTable ?
              <div className="mt-3">
                <FeesConfigTable customerId={profileData.user_id} profileData={profileData}/>
              </div>
              : null}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Overview

import React, {useContext, useEffect, useState} from 'react'
import {Button, Col, Form, Input, Label, Row} from "reactstrap"
import {ModalContext} from "../../../../contexts"
import {updateSecurity} from "../../../../api/securities"
import {toast} from "react-toastify"
import {selectListData} from "../../../../api/general"
import Select from "react-select"
import {slugify} from "../../../../helpers/utils"
import {getAllAssets} from "../../../../api/transactions"

const EditSecurity = (props) => {
  const {data, updateTable} = props
  const {closeModal} = useContext(ModalContext)
  const [securityName, setSecurityName] = useState('')
  const [fieldsData, setFieldsData] = useState({})
  const [pagesOption, setPagesOption] = useState([])
  const [securityPage, setSecurityPage] = useState()
  const [newFieldLabel, setNewFieldLabel] = useState('')
  const [assetOptions, setAssetOptions] = useState([])

  console.log(fieldsData);
  const submitHandler = (e) => {
    e.preventDefault()

    let formData = {
      name: securityName,
      type: data.type,
      page_id: securityPage?.value || '',
      fields: fieldsData
    }

    updateSecurity(data.id, formData).then(r => {
      if (r.success) {
        toast.success(r.message)
        updateTable()
        closeModal()
      } else {
        toast.error(r.message)
      }
    })
  }

  const addNewFieldsHandler = (e) => {
    e.preventDefault()

    if (!newFieldLabel) return toast.info('Label is required field!')

    const key = slugify(newFieldLabel)

    if (Object.keys(fieldsData).includes(key)) {
      return toast.info('Such field already exists!')
    }

    const updatedFields = {
      ...fieldsData,
      [key]: {label: newFieldLabel, type: "text", is_custom: true}
    }

    const sortedFields = Object.fromEntries(
      Object.entries(updatedFields).sort(([, a], [, b]) => {
        if (a.type === 'textarea') return 1
        if (b.type === 'textarea') return -1
        return 0
      })
    )

    setFieldsData(sortedFields)
    setNewFieldLabel('')
  }

  const deleteCustomFieldHandler = (key) => {
    setFieldsData(prevMetaData => {
      const { [key]: _, ...rest } = prevMetaData
      return {...rest}
    })
  }

  const getAssets = () => {
    getAllAssets().then(r => {
      setAssetOptions(r.map(i => ({...i, label: i.label + ' / ' + i.code})))
    })
  }

  const changeMetaValuesHandler = (value, key) => {
    setFieldsData(prevMetaData => ({
      ...prevMetaData,
      [key]: {...prevMetaData[key], value: value}
    }))
  }

  const changePayoutAssetCodeHandler = (code, key) => {
    if (fieldsData && fieldsData['payout_asset']) {
      setFieldsData(prevMetaData => ({
        ...prevMetaData,
        [key]: {...prevMetaData[key], payout_asset_code: code}
      }))
    }
  }

  useEffect(() => {
    if (data) {
      setSecurityName(data.name)
      setFieldsData(data.fields)
    }
  }, [data])

  useEffect(() => {
    selectListData('pages_list').then(r => {
      setPagesOption(r)
    })
  }, [])

  useEffect(() => {
    if (data.page_id) {
      setSecurityPage(pagesOption.find(i => i.value === data.page_id))
    }
  }, [pagesOption, data])

  useEffect(() => {
    if (fieldsData.hasOwnProperty('payout_asset') && !assetOptions?.length) {
      getAssets()
    }
  }, [fieldsData])

  return (
    <>
      <Form onSubmit={e => submitHandler(e)}>
        <Row>
          <div className="form-group mb-3">
            <Label className="form-label mb-0">Name</Label>
            <Input
              name="name"
              required
              placeholder="Name"
              autoComplete="off"
              value={securityName}
              onChange={e => setSecurityName(e.target.value)}
            />
          </div>

          <div className="form-group mb-3">
            <Label htmlFor="choices-multiple-default" className="form-label mb-0">Page</Label>
            <Select
              placeholder="Choose..."
              isClearable={true}
              name="page_id"
              value={securityPage}
              onChange={selected => setSecurityPage(selected)}
              options={pagesOption}
            />
          </div>

          {Object.values(fieldsData).length ?
            Object.entries(fieldsData).map(([key, values], idx) => {

              const isPopular = key === 'popular'
              const popularMessage = 'Check the box to display it in the popular list'
              let options = []

              if (values.type === 'select' && values.options) {
                options = Object.entries(values.options).map(([key, value]) => ({
                  value: key,
                  label: value
                }))
              }

              return (
                <Col
                  className={`form-group mb-2 ${values.type === 'textarea' ? 'col-12' : 'col-12 col-lg-6'}
                    ${values.type === 'checkbox' ? 'd-flex align-items-center flex-row-reverse justify-content-end' : ''}`
                  }
                  key={idx}
                >
                  <div className="d-flex align-items-end">
                    <div className={`w-100 ${values.type === 'checkbox' ? 'd-flex align-items-center flex-row-reverse justify-content-end' : ''}`}>
                      <Label className={`custom-label ${values.type === 'checkbox' ? 'pb-1' : ''}`}>
                        {values.label}
                        {isPopular && <span className="ms-1 fs-12 text-muted">({popularMessage})</span>}
                      </Label>
                      {values.type === 'select' ?
                        <Select
                          placeholder="Enter the value"
                          name={key || `field_${idx}`}
                          value={key === 'payout_asset' ?
                            (assetOptions.find(i => i.value === Number(values.value)) || null) :
                            (options.find(i => i.value === values.value) || null)}
                          onChange={selected => {
                            changeMetaValuesHandler(selected.value, key)

                            if (key === 'payout_asset') {
                              changePayoutAssetCodeHandler(selected.code, key)
                            }
                          }}
                          options={key === 'payout_asset' ? assetOptions : options}
                          required={values.required}
                        />
                        :
                        <Input
                          placeholder="Enter the value"
                          name={key || `field_${idx}`}
                          type={values.type}
                          value={values.type === 'checkbox' ? undefined : (values.value || '')}
                          checked={values.type === 'checkbox' ? (values.value || false) : undefined}
                          onChange={e =>
                            changeMetaValuesHandler(values.type === 'checkbox' ? e.target.checked : e.target.value, key)
                          }
                          autoComplete='off'
                          required={values.required}
                        />
                      }
                    </div>
                    {values.is_custom ?
                      <button
                        type="button"
                        className="btn btn-danger px-3 btn-icon waves-effect waves-light ms-2"
                        onClick={() => deleteCustomFieldHandler(key)}
                      >
                        <i className="mdi mdi-archive-remove-outline fs-20"></i>
                      </button> : null
                    }
                  </div>
                </Col>
              )
            }) : null
          }

          {Object.keys(fieldsData).length ?
            <div className="d-flex align-items-center py-1 mt-2">
              <span className="text-muted">Add field</span>
              <hr className="p-0 m-0 flex-1 ms-2"/>
            </div> : null
          }
        </Row>

        {Object.keys(fieldsData).length ?
          <Row className="mt-2">
            <Col className="d-flex align-items-end">
              <div className="form-group w-100">
                <Label className='custom-label'>Label</Label>
                <Input
                  placeholder="Field label"
                  type="text"
                  autoComplete='off'
                  value={newFieldLabel}
                  onChange={e => setNewFieldLabel(e.target.value)}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary px-3 btn-icon waves-effect waves-light ms-4"
                onClick={e => addNewFieldsHandler(e)}
              >
                <i className="mdi mdi-layers-plus fs-20"></i>
              </button>
            </Col>

          </Row> : null
        }

        <div className="d-flex justify-content-between mt-3">
          <Button type="button" color="warning" onClick={closeModal} className="btn">Cancel</Button>
          <Button type="submit" color="primary" className="btn">Submit</Button>
        </div>
      </Form>
    </>
  )
}

export default EditSecurity

import {Button, Col, Form, Input, Label, Row} from "reactstrap"
import React, {useContext, useEffect, useState} from "react"
import Select from "react-select"
import {selectListData} from "../../../../api/general"
import {ModalContext} from "../../../../contexts"
import {toast} from "react-toastify"
import {createSecurity, getMeta} from "../../../../api/securities"
import {slugify} from "../../../../helpers/utils"
import {getAllAssets} from "../../../../api/transactions"

const addSecurity = ({updateTable}) => {
  const {closeModal} = useContext(ModalContext)
  const [securityOptions, setSecurityOptions] = useState([])
  const [pagesOption, setPagesOption] = useState([])
  const [selectedSecurity, setSelectedSecurity] = useState([])
  const [selectedSecuritiesFields, setSelectedSecuritiesFields] = useState({})
  const [newFieldLabel, setNewFieldLabel] = useState('')
  const [assetOptions, setAssetOptions] = useState([])
  const [payoutAssetCode, setPayoutAssetCode] = useState('')

  const submitHandler = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target)
    let data = Object.fromEntries(formData.entries())

    let fields = {}

    for (const key in selectedSecuritiesFields) {
      if (selectedSecuritiesFields.hasOwnProperty(key)) {
        if (data.hasOwnProperty(`meta_${key}`)) {
          fields[key] = {
            ...selectedSecuritiesFields[key],
            value: data[`meta_${key}`],
            ...((key === 'payout_asset') && {payout_asset_code: payoutAssetCode || ''})
          }
        } else {
          fields[key] = {
            ...selectedSecuritiesFields[key],
            value: '',
            ...((key === 'payout_asset') && {payout_asset_code: payoutAssetCode || ''})
          }
        }
      }
    }

    const newData = {
      name: data.name,
      type: data.type,
      page_id: data.page_id,
      fields: fields
    }

    createSecurity(newData).then(r => {
      let message = r?.message || 'Empty response message.'

      if (r.success) {
        toast.success(message)
        closeModal()
        updateTable()
      } else {
        toast.error(message)
      }
    })
  }

  const getSecuritiesMeta = (type) => {
    getMeta(type).then(r => {
      if (r.success) {
        setSelectedSecuritiesFields(r.fields)
      } else {
        toast.error(r.message)
      }
    })
  }

  const addNewFieldsHandler = (e) => {
    e.preventDefault()

    if (!Object.keys(selectedSecuritiesFields).length) {
      return toast.info('Please select a type first.')
    }
    if (!newFieldLabel) return toast.info('Label is required field!')

    const key = slugify(newFieldLabel)

    if (Object.keys(selectedSecuritiesFields).includes(key)) {
      return toast.info('Such label already exists!')
    }

    const updatedFields = {
      ...selectedSecuritiesFields,
      [key]: {label: newFieldLabel, type: "text", is_custom: true}
    }

    const sortedFields = Object.fromEntries(
      Object.entries(updatedFields).sort(([, fieldA], [, fieldB]) => {
        if (fieldA.type === 'textarea') return 1
        if (fieldB.type === 'textarea') return -1
        return 0
      })
    )

    setSelectedSecuritiesFields(sortedFields)
    setNewFieldLabel('')
  }

  const deleteCustomFieldHandler = (key) => {
    const updatedFields = Object.keys(selectedSecuritiesFields).reduce((acc, currentKey) => {
      if (currentKey !== key) {
        acc[currentKey] = selectedSecuritiesFields[currentKey]
      }
      return acc
    }, {})

    setSelectedSecuritiesFields(updatedFields)
  }

  const getAssets = () => {
    getAllAssets().then(r => {
      setAssetOptions(r.map(i => ({...i, label: i.label + ' / ' + i.code})))
    })
  }

  useEffect(() => {
    selectListData('securities_types_list').then(r => {
      setSecurityOptions(r)
    })

    selectListData('pages_list').then(r => {
      setPagesOption(r)
    })
  }, [])

  useEffect(() => {
    if (selectedSecuritiesFields.hasOwnProperty('payout_asset') && !assetOptions?.length) {
      getAssets()
    }
  }, [selectedSecuritiesFields])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          <div className="form-group mb-3">
            <Label className="form-label mb-0">Name</Label>
            <Input
              name="name"
              required
              placeholder="Name"
              autoComplete="off"
            />
          </div>
          <div className="form-group mb-3">
            <Label htmlFor="choices-multiple-default" className="form-label mb-0">Type</Label>
            <Select
              placeholder="Choose..."
              isClearable={true}
              name="type"
              onChange={(selected) => {
                setSelectedSecurity(selected)
                if (selected) {
                  getSecuritiesMeta(selected.value)
                }
              }}
              options={securityOptions}
              required
            />
          </div>

          <div className="form-group mb-3">
            <Label htmlFor="choices-multiple-default" className="form-label mb-0">Page</Label>
            <Select
              placeholder="Choose..."
              isClearable={true}
              name="page_id"
              options={pagesOption}
            />
          </div>

          {(selectedSecuritiesFields && Object.values(selectedSecuritiesFields)?.length) ?
            Object.values(selectedSecuritiesFields).map((field, idx) => {
              const fieldType = field.type
              const isPopular = field.label === 'Popular'
              const popularMessage = 'Check the box to display it in the popular list'
              let options = []

              if (fieldType === 'select' && field.options) {
                options = Object.entries(field.options).map(([key, value]) => ({
                  value: key,
                  label: value
                }))
              }

              return (
                <Col
                  className={`form-group mb-2 ${fieldType === 'textarea' ? 'col-12' : 'col-12 col-lg-6'}
                    ${fieldType === 'checkbox' ? 'd-flex align-items-center flex-row-reverse justify-content-end' : ''}`
                  }
                  key={idx}
                >
                  <div className="d-flex align-items-end">
                    <div className={`w-100 ${fieldType === 'checkbox' ? 'd-flex align-items-center flex-row-reverse justify-content-end' : ''}`}>
                      <Label className={`custom-label ${fieldType === 'checkbox' ? 'pb-1' : ''}`}>
                        {field.label}
                        {isPopular && <span className="ms-1 fs-12 text-muted">({popularMessage})</span>}
                      </Label>
                      {fieldType === 'select' ?
                        <Select
                          placeholder={field.placeholder ?? 'Enter the value'}
                          name={`meta_${Object.keys(selectedSecuritiesFields)[idx]}`}
                          autoComplete='off'
                          required={field.required}
                          options={field.label === 'Payout asset' ? assetOptions : options}
                          onChange={selected => {
                            if (Object.keys(selectedSecuritiesFields)[idx] === 'payout_asset') {
                              setPayoutAssetCode(selected.code)
                            }
                          }}
                        />
                        :
                        <Input
                          placeholder={field.placeholder ?? 'Enter the value'}
                          name={`meta_${Object.keys(selectedSecuritiesFields)[idx]}`}
                          type={field.type}
                          autoComplete='off'
                          required={field.required}
                        />
                      }
                    </div>

                    {field.is_custom ?
                      <button
                        type="button"
                        className="btn btn-danger px-3 btn-icon waves-effect waves-light ms-2"
                        onClick={() => deleteCustomFieldHandler(Object.keys(selectedSecuritiesFields)[idx])}
                      >
                        <i className="mdi mdi-archive-remove-outline fs-20"></i>
                      </button> : null
                    }
                  </div>
                </Col>
              )
            }) : ''
          }

          {Object.keys(selectedSecuritiesFields).length ?
            <div className="d-flex align-items-center py-1 mt-2">
              {/*<hr className="p-0 m-0 flex-1 me-2"/>*/}
              <span className="text-muted">Add field</span>
              <hr className="p-0 m-0 flex-1 ms-2"/>
            </div> : null
          }


          {/* Uncomment this block if need page id*/}
          {/*<div className="form-group mb-3">*/}
          {/*  <Label htmlFor="choices-multiple-default" className="form-label mb-0">Page</Label>*/}
          {/*  <Select*/}
          {/*    placeholder="Choose..."*/}
          {/*    isClearable={true}*/}
          {/*    name="aspect_ratio"*/}
          {/*    required*/}
          {/*  />*/}
          {/*</div>*/}
        </Row>

        {Object.keys(selectedSecuritiesFields).length ?
          <Row className="mt-2">
            <Col className="d-flex align-items-end">
              <div className="form-group w-100">
                <Label className='custom-label'>Label</Label>
                <Input
                  placeholder="Field label"
                  type="text"
                  autoComplete='off'
                  value={newFieldLabel}
                  onChange={e => setNewFieldLabel(e.target.value)}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary px-3 btn-icon waves-effect waves-light ms-4"
                onClick={e => addNewFieldsHandler(e)}
              >
                <i className="mdi mdi-layers-plus fs-20"></i>
              </button>
            </Col>

            <div className="hstack justify-content-end mt-3">
              <Button type="button" color="warning" onClick={closeModal} className="btn me-3">Close</Button>
              <Button type="submit" color="primary" className="btn">Save</Button>
            </div>
          </Row> : null
        }

      </Form>
    </>
  )
}

export default addSecurity
